const serviceTerm = `
<h3>제1조(목적)</h3>
<p>이 약관은 데일리인스타이 운영하는 데일리인스타 서비스(https://dailyinsta.co.kr, 이하 ‘데일리인스타’ 또는 ‘서비스’라 합니다) 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>

<h3>제2조 (정의)</h3>
<p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
<p>서비스라 함은 설치되어 있는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 데일리인스타 관련 제반 서비스를 의미합니다.</p>
<p>회원이라 함은 회사의 "서비스"에 접속하여 이 약관에 따라 "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.</p>
<p>게시물이라 함은 "회원"이 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</p>

<h3>제3조 (약관의 게시와 개정)</h3>
<p>1. "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 홈페이지 초기 화면에 게시합니다.</p>
<p>2. "회사"는 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 공지합니다.</p>
<p>3. "회사"가 전항에 따라 개정약관을 공지 또는 통지하면서 "회원"에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 "회원"이 명시적으로 거부의 의사표시를 하지 아니한 경우 "회원"이 개정약관에 동의한 것으로 봅니다.</p>
<p>4. "회원"이 개정약관의 적용에 동의하지 않는 경우 "회사"는 개정 약관의 내용을 적용할 수 없으며, 이 경우 "회원"은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 "회사"는 이용계약을 해지할 수 있습니다.</p>
<p>5. 이 약관의 일부가 집행 불능으로 판단되더라도 나머지 부분은 계속해서 효력을 갖습니다.</p>

<h3>제4조 (약관의 해석)</h3>
<p>1. "회사"는 개별 서비스에 대해서는 별도의 이용약관 및 운영정책(이하 "운영정책 등")을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 "운영정책 등"이 우선하여 적용됩니다.</p>
<p>2. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 "운영정책 등" 및 관계법령 또는 상관례에 따릅니다.</p>

<h3>제5조 (이용계약 체결)</h3>
<p>1. 이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</p>
<p>2. "회사"는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.</p>
<p>3. "회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</p>
<p>4. 제2항과 제3항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</p>
<p>5. 이용계약의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
<p>6. "회사"는 "회원"에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</p>

<h3>제6조 (회원정보의 변경)</h3>
<p>1. "회원"은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.</p>
<p>2. "회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하여 "회사"에 대하여 그 변경사항을 알려야 합니다.</p>
<p>3. 제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.</p>

<h3>제7조 (개인정보보호 의무)</h3>
<p>1. "회사"는 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의 개인정보취급방침이 적용됩니다. 다만, "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이 적용되지 않습니다.</p>

<h3>제8조 ("회원"에 대한 통지)</h3>
<p>1. "회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 SMS, 기타 "회사"가 적당하다고 판단하는 방법으로 할 수 있습니다.</p>
<p>2. "회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</p>

<h3>제9조 ("회사"의 의무)</h3>
<p>1. "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.</p>
<p>2. "회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.</p>
<p>3. "회사"는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 기타 "회사"가 적당하다고 판단하는 방법 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.</p>

<h3>제10조 ("회원"의 의무)</h3>
<p>1. "회원"은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다.</p>
<p>2. "회원"은 이 약관의 규정, 이용안내 및 게임서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다.</p>
<p>3. 회원의 계정 및 PC, 모바일 기기에 관한 관리 책임은 회원에게 있습니다. 회원의 계정 및 PC, 모바일 기기, 각종 인증수단의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을 지지 않습니다.</p>
<p>4. 회원은 부정한 결제가 이루어지지 아니하도록 보안수단을 설정하고 관리하여야 합니다. 이에 대한 회원의 부주의로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다.</p>

<h3>제11조("서비스"의 제공 등)</h3>
<p>1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.</p>
<p>2. 회사는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</p>
<p>3. "서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</p>
<p>4. "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제8조["회원"에 대한 통지]에 정한 방법으로 "회원"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</p>
<p>5. "회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</p>
<p>6. "회사"는 "회원" 간의 인스타그램 계정 팔로우, 게시물 좋아요를 편리하게 관리할 수 있는 기능을 제공합니다.</p>
<p>7. "회사"는 전항의 서비스의 원활한 제공을 위해 "회원"의 연동계정정보(인스타그램 아이디, 데일리인스타 충전금액 등) 정보를 이용할 수 있으며, 회사가 정한 제 3자에게 제공할 수 있습니다. 이용자는 이용내역을 본인 인스타그램 계정의 옵션> 회원님이 좋아한 게시물 또는 프로필의 ‘팔로잉' 에서 확인 할 수 있습니다.</p>
<p>8. 이용회원은 필요한 경우 무통장입금 결제수단을 이용하여 서비스 내 ‘충전금액’을 유상 충전하여 사용할 수 있습니다.</p>
<p>9. 이용회원은 충전금액을 본인의 거래에 대해서만 사용할 수 있으며, 어떠한 경우라도 타인에게 매매 또는 양도하거나, 실질적으로 매매 또는 양도와 동일하게 볼 수 있는 행위를 하여서는 아니 됩니다.</p>
<p>10. “회사”는 본 “서비스”의 전부 또는 일부를 본인 확인 여부, 등록정보 유무, 기타 “회사”에서 정한 조건에 따라 “회원”에게 차등적으로 제공할 수 있습니다.</p>

<h3>제12조 ("서비스"의 변경)</h3>
<p>1. "회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.</p>
<p>2. "서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 내 "공지사항" 화면 등 회원이 충분히 인지할 수 있는 방법으로 게시하여야 합니다.</p>
<p>3. "회사"는 서비스의 변경, 수정, 추가 시 전항의 방법으로 고지함으로써 별도의 절차 없이 "회원"의 연동계정정보(인스타그램 아이디, 데일리인스타 충전금액 등) 정보 이용에 동의한 것으로 보고 수정, 변경 추가된 서비스에서 정보를 사용 할 수 있습니다.</p>
<p>4. "회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.</p>

<h3>제13조 (정보의 제공 및 광고의 게재)</h3>
<p>1. "회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 SMS등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"에게 영리목적의 광고성 정보를 SMS등 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전 동의를 받아서 전송합니다.</p>
<p>2. "회원"은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 수신 거절을 할 수 있습니다.</p>
<p>3. "회사"는 "서비스"의 운영과 관련하여 "서비스" 화면, 홈페이지, 등에 광고를 게재할 수 있습니다.</p>
<p>4. "회원"은 "회사"가 제공하는 "서비스"와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.</p>

<h3>제14조 ("게시물"의 저작권)</h3>
<p>"회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</p>

<h3>제15조 ("게시물"의 관리)</h3>
<p>1. "회원"의 "게시물"이 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련법에 따라 조치를 취하여야 합니다.</p>
<p>2. "회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 임시조치 등을 취할 수 있습니다.</p>
<p>3. "회사"는 관계 법령 또는 이 약관의 준수 여부 등을 확인할 필요가 있을 때, "게시물"의 내용을 확인할 수 있습니다.</p>
<p>4. "게시물" 일부는 일정 기간 이후에는 "서비스" 상에서 삭제될 수 있으며, "게시물"에 대한 관리 책임은 "회원"에게 있습니다.</p>

<h3>제16조 (권리의 귀속)</h3>
<p>1. "서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다. 단, "회원"의 "게시물" 및 제휴계약에 따라 제공된 저작물 등은 제외합니다. "회사"가 제공하는 서비스의 디자인, "회사"가 만든 텍스트, 스크립트(script), 그래픽, "회원" 상호간 전송 기능 등 "회사"가 제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에 관한 저작권 기타 지적재산권은 관련법령에 기하여 "회사"가 보유하고 있거나 "회사"에게 소유권 또는 사용권이 있습니다.</p>
<p>2. "회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라 "서비스" 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다. "회원"은 본 이용약관으로 인하여 "서비스"를 소유하거나 "서비스"에 관한 저작권을 보유하게 되는 것이 아니라, "회사"로부터 "서비스"의 이용을 허락 받게 되는바, "서비스"는 정보취득 또는 개인용도로만 제공되는 형태로 "회원"이 이용할 수 있습니다.</p>
<p>3. "회원"은 명시적으로 허락된 내용을 제외하고는 "서비스"를 통해 얻어지는 "회원" 상태정보를 영리 목적으로 사용, 복사, 유통하는 것을 포함하여 "회사"가 만든 텍스트, 스크립트, 그래픽의 "회원" 상호간 전송기능 등을 복사하거나 유통할 수 없습니다.</p>
<p>4. "회원"은 "회사"의 명백한 서면 허가를 받은 경우를 제외하고는 "서비스" 또는 이에 포함된 소프트웨어와 관련된 파생물 제작, 역파일, 소스 코드의 추출을 시도할 수 없습니다</p>

<h3>제17조 (계약해제, 해지 등)</h3>
<p>1. "회원"은 로그인에 사용한 인스타그램 계정의 비밀번호를 변경하는방법으로, 서비스의 이용해지를 할 수 있습니다. 또한 별도의 신청을 통하여 이용계약 해지 신청을 할 수 있으며, "회사"는 관련법령 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.</p>
<p>2. "회원"이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 "회사"가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는 소멸되며 이는 복구할 수 없습니다.</p>
<p>3. "회원"이 계약을 해지하는 경우, "회원"을 식별할 수 있는 프로필 사진 등과 같이 본인계정에 등록된 내용 일체는 삭제됩니다. 다만, 타인에 의해 담기, 스크랩 등이 되어 재게시 되거나, 공용게시판에 등록된 "게시물" 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.</p>

<h3>제18조 (구매금액의 환불)</h3>
<p>1. 환불은 결제 완료 후 48시간 이내에만 가능합니다.(단, 지급된 포인트를 사용하신 경우와 언팔체크 서비스를 통해 회수한 포인트는 환불이 불가능하오니 이점 유의하여 주시기 바랍니다.)</p>
<p>2. 회원이 회사에 구매금액을 환불요청 하는 경우 다음의 정보가 필요할 수 있습니다.</p>
<p>가. 무통장 : 요청일로부터 3일 이내 지급 필요정보(이메일주소, 본인 이름, 환불계좌, 환불계좌 은행명)</p>
<p>3. 언팔체크로 인한 환급 포인트는 18조 1항의 내용과 같이 데일리인스타만의 자체 서비스 입니다. 인스타그램측 로직 업데이트등의 경우로 부득이하게 구매단가가 인상되었을 경우에는 구매당시 소진하셨던 금액으로 언팔체크 포인트가 환급 됩니다.</p>

<h3>제19조 (이용제한 등)</h3>
<p>1. "회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.</p>
<p>2. "회사"는 전항에도 불구하고, 불법프로그램의 제공 및 운영방해, 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 "서비스" 이용을 통해 획득한 혜택 등도 모두 소멸되며, "회사"는 이에 대해 별도로 보상하지 않습니다.</p>
<p>3. "회사"는 "회원"이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</p>
<p>4. "회사"는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.</p>
<p>5. 본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 "회사"는 제8조["회원"에 대한 통지]에 따라 통지합니다.</p>
<p>6. "회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는 경우 "회사"는 즉시 "서비스"의 이용을 재개합니다.</p>

<h3>제20조 (책임제한)</h3>
<p>1. "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.</p>
<p>2. "회사"는 단말기 등 접근 매체의 분실로 인한 서비스 이용 장애 등 "회원"의 귀책사유로 인한 "서비스" 이용의 장애 및 통신 장애 등으로 인한 서비스 이용 장애 등에 대하여는 책임을 지지 않습니다.</p>
<p>3. "회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</p>
<p>4. "회사"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</p>
<p>5. "회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</p>

<h3>제21조 (준거법 및 재판관할)</h3>
<p>1. "회사"와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다</p>`;

export default serviceTerm;
