import axios from "axios";

const client = axios.create({
  baseURL: "https://api.dailyinsta.co.kr",
});

// const client = axios.create({
//   baseURL: "http://localhost:8004",
// });

client.interceptors.request.use(
  (config) => {
    config.headers!["Authorization"] = window.localStorage.getItem("jwt") ?? "";

    return config;
  },
  (err) => Promise.reject(err)
);

export default client;
